import React from 'react';

import { Helmet } from 'react-helmet';

import Header from 'components/common/header';
import Main from 'components/common/main';
import Footer from 'components/common/footer';
import { PageMeta } from 'constants/page_meta';
import { TitleBar } from 'lib/TitleBar';
import cyclist from 'assets/images/about/cyclist.png';
import guitarist from 'assets/images/about/guitarist.png';
import runner from 'assets/images/about/runner.png';
import skater from 'assets/images/about/skater.png';
import {
	PageContainer,
	SectionTitle,
	ContentText,
	BoldText,
	StaticList,
	SectionSubTitle,
	AboutImageContainer,
} from './styles';

const meta = PageMeta.getStatic('about');

const AboutUs = () => {
	return (
		<Main>
			<Helmet>
				<title>{meta.title}</title>
				<meta name="description" content={meta.desc} />
			</Helmet>
			<Header searchable />
			<TitleBar title="About us"></TitleBar>
			<PageContainer>
				<SectionTitle>We'd love to share our vision with you!</SectionTitle>
				<ContentText>
					It all started in 2018 at the peak of the student accommodation protests. Alexandria
					Procter, the boss lady, was sitting in her SRC office in the Steve Biko building at the
					University of Cape Town daydreaming about innovative ways to connect landlords who have
					empty beds and students who needed accommodation. The solution she came up with? An excel
					spreadsheet.
				</ContentText>
				<ContentText>
					She managed to convince Greg Keal to jump on board, and DigsConnect Version 1 was born.
				</ContentText>
				<ContentText>
					Fast forward to 2021. We are now the largest platform connecting young people with the
					homes of their dreams on the African continent.
					<BoldText>
						{' '}
						We’ve connected more than 50 000 people on our platform over the past 36 months and
						counting!
					</BoldText>
				</ContentText>
				<ContentText>
					The core value proposition hasn’t changed from day 1, it’s just more user-friendly.
					Landlords list their properties on our website, and young people visit the website to send
					an enquiry directly to the landlord.
				</ContentText>
				<ContentText>
					We know that finding and renting out a home is about more than you can share in a little
					form on a website, so we’ve introduced Digs Chat directly linked to the booking form so
					that you can get to know each other before signing any documents.
				</ContentText>
				<ContentText>
					Throughout our history it has remained totally free for landlords to list on our platform,
					charging a small service fee only when they are able to successfully place a student in
					their property.
				</ContentText>
				<ContentText>
					Safety on our platform is super important to us. We require all users to add a profile
					picture, validate their cell phone number and email address and to provide their ID
					number, just to check that you’re chatting to the person you think that you are. That
					said, we still encourage users just to make extra sure before making any big decisions. It
					is also recommended that you stay on Digs Chat and don’t move onto other chat apps in
					order to protect your privacy and security.
				</ContentText>
				<ContentText>
					We’re building a community of students, tenants, landlords and partnered academic
					institutions who share our vision of creating a platform that connects people in a safe
					and secure environment around the world.
				</ContentText>
				<AboutImageContainer>
					<img src={cyclist} alt="A digs connect cyclist graphic" />
					<img src={runner} alt="A digs connect runner graphic" />
					<img src={skater} alt="A digs connect skater graphic" />
					<img src={guitarist} alt="A digs connect guitarist graphic" />
				</AboutImageContainer>

				<SectionTitle>For Landlords</SectionTitle>
				<ContentText>
					We’ve designed a booking form that tenants complete which includes:
				</ContentText>
				<StaticList indent={1}>
					<li>A profile picture of the tenant</li>
					<li>Name </li>
					<li>Ideal move-in date</li>
					<li>Dream lease period</li>
					<li>Whether they are a student or a young adult</li>
					<li>Where they studying (if applicable)</li>
					<li>Whether they are an undergrad or postgrad (if applicable)</li>
					<li>The rent payer</li>
				</StaticList>
				<ContentText>
					By adding this we are able to screen for quality and intent, saving you time scrolling
					through hundreds of non-starter enquiries from other platforms. We then ask them to send
					the first message in the Digs chat to ask any questions they may have. You can then chat
					directly with them!
				</ContentText>
				<ContentText>
					You’ll get an email and SMS every time you get an enquiry or new message. Then the timer
					starts! You only have 48 hours to get back to the tenant, or the booking enquiry will
					expire. We have found that the faster the reply, the higher the likelihood of the deal
					being successful.
				</ContentText>
				<ContentText>
					We also have a full-time team who are available to guide you through the entire process!
					We only charge on success, so if you don’t find a tenant that meets your requirements then
					you don’t pay.
				</ContentText>

				<SectionTitle>For tenants</SectionTitle>
				<ContentText>
					We’ve partnered with most private colleges in South Africa and a few public universities
					to bring you a selection of options close to your campus.
				</ContentText>
				<ContentText>
					On every single listing, there is a little map with a drop-down for you to select your
					college and it’ll give you the walking and driving distance from the property to your
					campus.
				</ContentText>
				<ContentText>
					We have a team that chats to every landlord on our platform making sure they have
					validated their cell phone number, email address and provided us with their ID number. We
					still ask that you always keep your conversations to the Digs Chat though and don’t share
					any personal information before double-checking the landlord's details and identity.
				</ContentText>
				<ContentText>
					Once you’ve filled in the short booking enquiry form and sent the first message to the
					landlord they will have 48 hours to get back to you. From there you can chat till you’re
					totally happy with the place, and then you can conclude the deal. Easy as that.
				</ContentText>
				<ContentText>You’ll get an email and SMS every time you get a new message.</ContentText>
				<ContentText>
					If you don’t find something you totally love, then add an alert from the Find a Digs page
					and whenever a property is added that meets your requirements, we’ll send you an email
					with a link to the listing.
				</ContentText>
				<SectionTitle>Booking statuses explained</SectionTitle>
				{/* TODO: Add correct aria labels */}
				<SectionSubTitle>
					Pending
					<span role="img" aria-label="">
						⏰
					</span>
				</SectionSubTitle>
				<ContentText>
					Congratulations, this means that you have either sent or received a booking enquiry. If
					you’re a tenant, you need to wait for the landlord to reply with the 48 hour time window.
					If you’re a landlord, you need to reply to the tenant’s message to avoid the booking
					enquiry expiring in the 48-hour window.
				</ContentText>
				<SectionSubTitle>
					Connected
					<span role="img" aria-label="">
						⏰
					</span>
				</SectionSubTitle>
				<ContentText>
					Yippee, this means that the landlord has replied to the tenant and you are now connected
					and ready to take over the world.. Or at least start communications for your next digs
				</ContentText>
				<SectionSubTitle>
					Confirmed
					<span role="img" aria-label="">
						✅
					</span>
				</SectionSubTitle>
				<ContentText>
					Hooray! This means that either the landlord or tenant has confirmed that the booking
					enquiry was successful, and you’ve sealed the deal.
				</ContentText>
				<SectionSubTitle>
					Cancelled
					<span role="img" aria-label="">
						👎
					</span>
				</SectionSubTitle>
				<ContentText>
					Ah man, this means that either the landlord or tenant has cancelled the booking enquiry
					either before or after receiving a reply. If you’re a tenant you can still resend the
					enquiry if you’d like.
				</ContentText>
				<SectionSubTitle>
					Expired
					<span role="img" aria-label="">
						⌛
					</span>
				</SectionSubTitle>
				<ContentText>
					This means that the landlord didn’t respond to the tenant’s inquiry message within 48
					hours. If you’re a tenant you can still resend the enquiry if you’d like.
				</ContentText>
				<SectionSubTitle>
					Denied
					<span role="img" aria-label="">
						😶
					</span>
				</SectionSubTitle>
				<ContentText>
					Woops! This means that the landlord received the enquiry but chose to decline it.
					Oftentimes this is because the property is full, or no longer available.
				</ContentText>
			</PageContainer>
			<Footer />
		</Main>
	);
};

export default AboutUs;
